import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import $ from 'jquery'
import 'intersection-observer';
import { SaveItem, useUserObjectState, useAuthState } from "@starberry/myaccount-website-utils";
//import { useInView } from 'react-intersection-observer';
//import { useLocation } from "@reach/router"
/**
 * Assets
 */
import './styles/_index.scss';
//import { Heart, Share } from '@components/icon/icon';
//import StickyNavArea from '@components/sticky-nav-area/sticky-nav-area'
//import SendFriendForm from '@components/forms/sendfrnd-form';
//import Modal from "react-bootstrap/Modal"
import SocialShare from "@components/property-top/social-share";

import { ArrowLeft, Bedroom, Bathroom, Reception } from '@components/icon/icon';
import GetOffice from './get-office';


const PropertyTop = (props) => {

    const { state, services } = useUserObjectState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()  
  
    useEffect(() => {
      if (isAuthenticated) {
      // on page load get the users saved objects
          services.getUserobject({ type: "property", state:true })
      }
    }, [])

    function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
            scrollTop: $(thishash).offset().top-50
        }, 2000);
    }
    const handleBack = () => {
        $("html, body").scrollTop(0);
    }
    var price= Math.round(props.price)
    var BackURL = ''
    if(props.searchtype === "sales") {
        BackURL = '/property/for-sale/in-london'
    } else {
        BackURL = '/property/to-rent/in-london'
    }
    return (
        <div className="property-top">          
                <div className="justify-content-between property-row property-head">   
                    <div className="justify-content-between property-head-text">                
                                <h1 className='h4'>                          
                                    {props.address}
                                </h1>
                                <SocialShare {...props}/>
                    </div>
                            {(props.price === undefined || props.price === 0) ? "":
                            <h2 className="pricetop">£{price.toLocaleString()} <span>
                            {props.searchtype === 'lettings'?<span>pcm <Link to="/property-services/rent/tenant-fees/">fees apply</Link></span>:''}
                            {props.pricequalifier && props.searchtype !== 'lettings'?props.pricequalifier:''}</span></h2>
                            }
                </div>
                <div  className="justify-content-between property-row property-block">                   
                        <ul className='property-block-list'>                          
                                {/*<Col md="3">
                                    <h6>Price:</h6>
                                    <h5>£{price.toLocaleString()}</h5>
                                </Col>*/}
                            
                                {props.bedroom > 0 && props.bedroom && 
                                <li>
                                    <div className="icon-detail-top text-20">
                                        <Bedroom/>{props.bedroom} <span>{props.bedroom > 1 ? 'Bedrooms' : 'Bedroom'}</span>
                                    </div>
                                </li>
                                }
                                {props.bathroom > 0 && props.bathroom &&
                                <li>
                                    <div className="icon-detail-top text-20">
                                        <Bathroom/>{props.bathroom} <span>{props.bathroom > 1 ? 'Bathrooms' : 'Bathroom'}</span>
                                    </div>
                                </li>
                                }
                                {props.reception > 0 && props.reception &&
                                <li>
                                    <div className="icon-detail-top text-20">
                                        <Reception/>{props.reception} <span>{props.reception > 1 ? 'Receptions' : 'Reception'}</span>
                                    </div>
                                </li>
                                }
                        </ul>
                </div>
                   
                   {/* <Col lg="3" className="d-none d-lg-block">
                        <GetOffice officecrmid={props.officecrmid}/>
                        
                        </Col> */}

        </div>
    )
}

export default PropertyTop
